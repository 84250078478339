import { Fragment } from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import type { SxProps } from '@mui/material';
import GridSection from '../common/GridSection';
import { buttonTheme } from '../../styles/customedMuiTheme';

type Props = {
  sx?: SxProps;
  gridRow: number;
  onInitClick: () => void;
  onLookupClick: () => void;
};

const SearchButtonGroup = (props: Props) => {
  const { sx, gridRow, onInitClick, onLookupClick } = props;
  return (
    <Fragment>
      <Divider
        sx={{
          gridRow,
          gridColumn: '1/-1',
          pb: 1,
          mb: 1,
        }}
      />
      <GridSection
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 1,
          gridColumn: '1/-1',
          gridRow: gridRow + 1,
          ...sx,
        }}
      >
        <Button variant="outlined" onClick={onInitClick} sx={buttonTheme.medium}>
          초기화
        </Button>
        <Button variant="contained" onClick={onLookupClick} sx={buttonTheme.medium}>
          조회
        </Button>
      </GridSection>
    </Fragment>
  );
};

export default SearchButtonGroup;
