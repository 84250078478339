import { useQuery } from '@tanstack/react-query';
import fetcher from '../libs/common/fetcher';

export interface PackingBox {
  pGoodsNo: number;
  pGoodsId: string;
  pGoodsName: string;
}

export interface PackingBoxOption {
  displayName: string;
  value: string;
  order: number;
}

function getValidResponse<T>(data: T[] | undefined): T[] {
  return data !== undefined && Array.isArray(data) ? data : [];
}

export const usePackingBox = () => {
  return useQuery<PackingBoxOption[]>({
    queryKey: ['packingBoxes'],
    queryFn: async () => {
      const response = await fetcher('packings/boxes');
      const validSuppliers = getValidResponse<PackingBox>(response);
      return validSuppliers.map(packingBox => ({
        displayName: packingBox.pGoodsName,
        value: packingBox.pGoodsName,
        order: 1,
      }));
    },
    staleTime: Infinity,
  });
};
