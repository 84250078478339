import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiTableCell, { tableCellClasses } from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { styled } from '@mui/material/styles';
import AutoContainImage from '../../../../../components/common/Image/AutoContainImage';
import { COLORS } from '../../../../../consts/common/colors';
import FormModal from '../../../../../components/common/FormModal';
import { getFinalUrl } from '../../../../../consts/outbound/imageUrl';
import ImageZoomModal from '../../../../../components/ImageZoomModal';

type Tote = {
  toteBarcode: string;
  totalSkuCount: number;
  totalQuantity: number;
};

type ShippingItem = {
  itemId: number;
  itemName: string;
  code: string;
  imageUrl: string;
  barcode: number;
  offlineBarcode: number;
  remark: string;
  orderQuantity: number;
};

type Info = {
  pickingGroupSerialNumber: string;
  sameShippingCount: number;
  totalWeightOfShipping: string;
  totes: Tote[];
  shippingItems: ShippingItem[];
};

type Props = {
  title: string;
  info: Info | undefined;
  onClose: () => void;
};

const PackingInfoModal = ({ title, info, onClose }: Props) => {
  if (!info) return <></>;

  const [enlargedImageUrl, setEnlargedImageUrl] = useState('');

  return (
    <FormModal open={!!info} title={title} onClose={onClose}>
      <Stack width={800} gap={1}>
        <Typography sx={styles.modalSubTitle}>집품그룹 정보</Typography>
        <TableContainer component={Paper}>
          <Table size={'small'} sx={{ border: `1px solid ${COLORS.lightLine}` }}>
            <TableRow>
              <HeadTableCell>집품그룹ID</HeadTableCell>
              <BodyTableCell colSpan={3}>{info.pickingGroupSerialNumber}</BodyTableCell>
            </TableRow>
            <TableRow>
              <HeadTableCell>출고건수</HeadTableCell>
              <BodyTableCell>{info.sameShippingCount}</BodyTableCell>
              <HeadTableCell>1박스 실제중량무게(g)</HeadTableCell>
              <BodyTableCell>{info.totalWeightOfShipping}</BodyTableCell>
            </TableRow>
            <TableRow>
              <HeadTableCell>전체 품목수량</HeadTableCell>
              <BodyTableCell>
                {info.totes.reduce((acc, curr) => acc + curr.totalSkuCount, 0)}
              </BodyTableCell>
              <HeadTableCell>전체 아이템수량</HeadTableCell>
              <BodyTableCell>
                {info.totes.reduce((acc, curr) => acc + curr.totalQuantity, 0)}
              </BodyTableCell>
            </TableRow>
          </Table>
        </TableContainer>
        <Typography sx={styles.modalSubTitle}>집품그룹단위 토트정보</Typography>
        <TableContainer component={Paper}>
          <Table size={'small'} sx={{ border: `1px solid ${COLORS.lightLine}` }}>
            <TableHead sx={{ backgroundColor: COLORS.bgLight }}>
              <TableRow>
                <TableCell>{'NO'}</TableCell>
                <TableCell>{'토트'}</TableCell>
                <TableCell>{'품목수량'}</TableCell>
                <TableCell>{'아이템수량'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {info.totes?.map((tote: Tote, index: number) => (
                <TableRow key={tote.toteBarcode}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{tote.toteBarcode}</TableCell>
                  <TableCell>{tote.totalSkuCount}</TableCell>
                  <TableCell>{tote.totalQuantity}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography sx={styles.modalSubTitle}>1박스 포장단위 아이템정보</Typography>
        <TableContainer component={Paper}>
          <Table size={'small'} sx={{ border: `1px solid ${COLORS.lightLine}` }}>
            <TableHead sx={{ backgroundColor: COLORS.bgLight }}>
              <TableRow>
                <TableCell>{'NO'}</TableCell>
                <TableCell>{'바코드'}</TableCell>
                <TableCell>{'아이템코드'}</TableCell>
                <TableCell>{'이미지'}</TableCell>
                <TableCell>{'아이템명'}</TableCell>
                <TableCell>{'아이템수량'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {info.shippingItems?.map((item: ShippingItem, index: number) => (
                <TableRow key={item.itemId}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.offlineBarcode || item.barcode}</TableCell>
                  <TableCell>{item.code}</TableCell>
                  <TableCell>
                    <Box onClick={() => setEnlargedImageUrl(getFinalUrl(item.imageUrl))}>
                      <AutoContainImage src={getFinalUrl(item.imageUrl)} imageContainerSize={40} />
                    </Box>
                  </TableCell>
                  <TableCell>
                    {item.itemName}
                    {item.remark && (
                      <span style={{ color: COLORS.red }}>{`=> ${item.remark}`}</span>
                    )}
                  </TableCell>
                  <TableCell>{item.orderQuantity}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack flexDirection={'row'} justifyContent={'center'} gap={1} m={1}>
          <Button variant={'outlined'} onClick={onClose}>
            닫기
          </Button>
        </Stack>
        <ImageZoomModal imageUrl={enlargedImageUrl} onClose={() => setEnlargedImageUrl('')} />
      </Stack>
    </FormModal>
  );
};

export default PackingInfoModal;

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: COLORS.bgLight,
    color: theme.palette.common.black,
    fontSize: 13,
    textAlign: 'center',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    textAlign: 'center',
  },
}));

const HeadTableCell = styled(MuiTableCell)(({ theme, width }) => ({
  backgroundColor: COLORS.bgLight,
  color: theme.palette.common.black,
  fontSize: 13,
  width: width || 200,
}));

const BodyTableCell = styled(MuiTableCell)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: 13,
}));

const styles = {
  modalSubTitle: { fontSize: '22px', mt: 1.5 },
};
