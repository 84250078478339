import { atom } from 'recoil';
import { RefCodeOption } from '../../queries/useRefCode';

export interface RefCodeOptions {
  displayName: string;
  value: string;
}

export const refCodeOptionsAtom = atom<RefCodeOption | undefined>({
  key: 'refCodeOptionsAtom',
  default: {},
});

export default refCodeOptionsAtom;
