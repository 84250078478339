import type { GridColDef } from '@mui/x-data-grid-pro';
import { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import { useRecoilValue } from 'recoil';

const gridColumns = () => {
  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const adjustedInventoryHistoryTypeOptions = refCodeOptions?.inventoryMovementHistoryType || [];
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 10,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'type',
      headerName: '조정사유',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return adjustedInventoryHistoryTypeOptions.find(option => option.value === value)
          ?.displayName;
      },
    },
    {
      field: 'reason',
      headerName: '상세조정사유',
      minWidth: 330,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'initQuantity',
      headerName: '변경전 로케이션 가용수량',
      minWidth: 180,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'finalQuantity',
      headerName: '변경후 로케이션 가용수량',
      minWidth: 180,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'deltaQuantity',
      headerName: '변경수량',
      minWidth: 80,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'itemCode',
      headerName: '아이템코드',
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'itemName',
      headerName: '아이템명',
      minWidth: 250,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'barcode',
      headerName: '바코드',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'offlineBarcode',
      headerName: '오프라인 바코드',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'locationCode',
      headerName: '로케이션',
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'lpnCode',
      headerName: 'LPN',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'createdUser',
      headerName: '작업자',
      minWidth: 135,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'createdAt',
      headerName: '작업일시',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
