import { useQuery } from '@tanstack/react-query';
import fetcher from '../libs/common/fetcher';

export interface Carrier {
  id: number;
  code: string;
  name: string;
  displayOrder: number;
}

export const useCarriers = () => {
  return useQuery<Carrier[]>({
    queryKey: ['carriers'],
    queryFn: () => fetcher('/carriers', { sort: 'id,desc' }),
    staleTime: Infinity,
  });
};

function getValidResponse<T>(data: T[] | undefined): T[] {
  return data !== undefined && Array.isArray(data) ? data : [];
}
export interface CarrierOption {
  displayName: string;
  value: string;
  order: number;
}

export const useCarrierOptions = () => {
  return useQuery<CarrierOption[]>({
    queryKey: ['carrierOptions'],
    queryFn: async () => {
      const response = await fetcher('/carriers', { sort: 'id,desc' });
      const validResponses = getValidResponse<Carrier>(response);
      return validResponses
        ?.map(
          (carrier: Carrier) =>
            ({
              displayName: carrier.name,
              value: carrier.id.toString(),
              order: carrier.displayOrder,
            } as CarrierOption)
        )
        .sort((a: CarrierOption, b: CarrierOption) => a.order - b.order);
    },
    staleTime: Infinity,
  });
};
