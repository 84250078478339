import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import { SearchOption } from '../../types/form';
import { listItemTextTheme, selectTheme } from '../../styles/customedMuiTheme';

type Props = {
  field: string;
  options: SearchOption[];
  value: string;
  onChange: (field: string, value: string) => void;
};

const SearchSelect = (props: Props) => {
  const { field, value, onChange, options } = props;

  return (
    <FormControl fullWidth>
      <Select
        name={field}
        value={value}
        onChange={e => onChange(field, e.target.value)}
        sx={{
          '& .MuiSelect-select': {
            padding: 1,
          },
          ...selectTheme.medium,
        }}
      >
        {options.map(option => (
          <MenuItem key={option.displayName} value={option.value.toString()}>
            <ListItemText
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: 0,
                ...listItemTextTheme.medium,
              }}
              primary={option.displayName}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SearchSelect;
