import { ChangeEvent, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Cookies from 'js-cookie';
import axios, { AxiosError } from 'axios';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ListItem } from '@mui/material';
import { datadogRum } from '@datadog/browser-rum';
import Ktown4uLogo from 'components/common/Ktown4uLogo';
import { COLORS } from 'consts/common/colors';
import { REACT_APP_API_ENDPOINT_V2 } from 'consts/common/environment';
import { warehouseIdKey } from '../../components/common/Layout/AppBar';

type AuthResponseData = { errors: { wrong: string[] } };
type AuthError = AxiosError<AuthResponseData>;

const loginUserKey = 'loginUser';

const SignIn = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isWrongCredential, setIsWrongCredential] = useState<boolean>(false);
  const navigate = useNavigate();
  const deviceId = useLoaderData();

  useEffect(() => {
    const accessToken = Cookies.get('accessToken');
    if (accessToken) {
      return navigate('/gms', { replace: true });
    }
  }, []);

  const onChangeId = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onChangePasswd = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const onPressLogin = async () => {
    try {
      const { data } = await axios.post(`${REACT_APP_API_ENDPOINT_V2}/auth/login`, {
        loginId: email,
        password,
        deviceRegistrationNumber: deviceId,
      });
      localStorage.setItem(loginUserKey, data.loginId);
      Cookies.set('accessToken', data.token);
      datadogRum.setUser({
        id: data.loginId,
        name: data.loginId,
      });

      // 앱바에서 저장하는 주창고값을 로그인시 제거해서 초기화함.
      localStorage.removeItem(warehouseIdKey);

      navigate('/gms', { replace: true });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const authResponse = (error as AuthError).response;
        console.log(`Signin error: `, JSON.stringify(authResponse?.data?.errors));
      } else {
        console.log(`Unknown signin error: `, (error as Error).message);
      }
      setIsWrongCredential(true);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onPressLogin();
    }
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>케타포 GMS 로그인</title>
        </Helmet>
      </HelmetProvider>

      <Box sx={styles.container}>
        <Paper elevation={16} sx={styles.paper}>
          <Ktown4uLogo width={230} marginBottom={4} />
          <FormControl fullWidth sx={styles.formControl}>
            <TextField
              id="backoffice-id"
              label="아이디"
              variant="outlined"
              fullWidth
              value={email}
              onChange={onChangeId}
              sx={{ mb: 2 }}
              onKeyPress={handleKeyPress}
              InputLabelProps={{ sx: styles.inputLabel }}
            />

            <TextField
              id="password"
              label="비밀번호"
              variant="outlined"
              fullWidth
              value={password}
              onChange={onChangePasswd}
              type="password"
              onKeyPress={handleKeyPress}
              InputLabelProps={{ sx: styles.inputLabel }}
            />
          </FormControl>

          <Box sx={styles.warningTextContainer}>
            {isWrongCredential ? (
              <Typography sx={styles.warningText}>
                아이디 또는 비밀번호가 맞지 않습니다. 다시 로그인해주세요.
              </Typography>
            ) : null}
          </Box>

          <Button variant="contained" size="large" sx={styles.button} onClick={onPressLogin}>
            로그인
          </Button>
          <Notice />
        </Paper>
        <Box>
          <Typography sx={styles.copyright}>
            Copyright ⓒ (주)케이타운포유 all rights reserved.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default SignIn;

const Notice = () => (
  <List sx={styles.list}>
    <ListItem sx={styles.listItem}>
      <ListItemIcon sx={styles.listItemIcon}>-</ListItemIcon>
      <ListItemText
        sx={styles.listItemText}
        primary="본 시스템은 비인가자의 접근을 허용하지 않습니다."
      />
    </ListItem>
    <ListItem sx={styles.listItem}>
      <ListItemIcon sx={styles.listItemIcon}>-</ListItemIcon>
      <ListItemText
        sx={styles.listItemText}
        primary="본 시스템으로의 접근 시도는 기록에 남습니다."
      />
    </ListItem>
    <ListItem sx={styles.listItem}>
      <ListItemIcon sx={styles.listItemIcon}>-</ListItemIcon>
      <ListItemText
        sx={styles.listItemText}
        primary="본 시스템의 사용과 관련된 문의는 관리부서로 연락 주시기 바랍니다."
      />
    </ListItem>
  </List>
);

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: '#033971',
  },
  paper: {
    p: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mb: 2,
  },
  formControl: { mb: 1 },
  warningTextContainer: { width: '100%', height: '36px' },
  warningText: { fontSize: '13px', color: COLORS.red },
  button: { width: '100%', mb: 2, py: 2, bgcolor: COLORS.ktown4u, fontSize: 19 },
  copyright: { fontSize: '13px', color: COLORS.white },
  inputLabel: { color: COLORS.darkLine },
  list: { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' },
  listItem: { alignItems: 'start', padding: 0, fontSize: 15, color: COLORS.veryLightGrey },
  listItemIcon: {
    width: '8px',
    minWidth: '8px',
    paddingTop: '1.25px',
    color: COLORS.veryLightGrey,
  },
  listItemText: { margin: 0, fontSize: '15px' },
};
