import { useQuery } from '@tanstack/react-query';
import fetcher from '../libs/common/fetcher';

export interface Stocktaking {
  id: number;
  warehouseId: number;
  serialNumber: string;
  name: string;
  status: string;
}

export interface StocktakingOption {
  displayName: string;
  value: number;
  id: number;
  warehouseId?: number;
}

function getValidResponse<T>(data: T[] | undefined): T[] {
  return data !== undefined && Array.isArray(data) ? data : [];
}

export const useStocktaking = () => {
  return useQuery<StocktakingOption[]>({
    queryKey: ['stocktakings'],
    queryFn: async () => {
      const response = await fetcher(`/inventories/stocktakings`, { sort: 'id,desc' });
      const validStocktakings = getValidResponse<Stocktaking>(response);
      return validStocktakings
        .map(v => ({
          displayName: v.name,
          value: v.id,
          id: v.id,
          warehouseId: v.warehouseId,
        }))
        .sort((a: StocktakingOption, b: StocktakingOption) => b.id - a.id);
    },
    staleTime: Infinity,
  });
};

export const useStocktakingWithOutApplied = () => {
  return useQuery<StocktakingOption[]>({
    queryKey: ['stocktakingWithOutApplied'],
    queryFn: async () => {
      const response = await fetcher(`/inventories/stocktakings`, { sort: 'id,desc' });
      const validStocktakings = getValidResponse<Stocktaking>(response);
      return validStocktakings
        .filter((v: Stocktaking) => v.status !== 'APPLIED')
        .map(v => ({
          displayName: v.name,
          value: v.id,
          id: v.id,
          warehouseId: v.warehouseId,
        }))
        .sort((a: StocktakingOption, b: StocktakingOption) => b.id - a.id);
    },
    staleTime: Infinity,
  });
};
