import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Warehouse } from '../../../../store/outbound/warehouse.recoil';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import Box from '@mui/system/Box';
import AutoContainImage from '../../../../components/common/Image/AutoContainImage';
import { getFinalUrl } from '../../../../consts/outbound/imageUrl';

const gridColumns = (warehouseOption: Warehouse[]) => {
  const columns: GridColDef[] = [
    {
      field: 'rowIndex',
      headerName: 'NO',
      minWidth: 10,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'warehouseId',
      headerName: '창고',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      valueFormatter: ({ value }) => {
        return convertWarehouseFullCode(value, warehouseOption);
      },
    },
    {
      field: 'itemCode',
      headerName: '아이템코드',
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'imageUrl',
      headerName: '이미지',
      minWidth: 20,
      maxWidth: 50,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) =>
        params.value && (
          <Box
            sx={{ display: 'flex', alignItems: 'center', padding: 0.5, justifyContent: 'center' }}
          >
            <AutoContainImage src={getFinalUrl(params.value)} imageContainerSize={30} />
          </Box>
        ),
    },
    {
      field: 'itemName',
      headerName: '아이템명',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'barcode',
      headerName: '바코드',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'offlineBarcode',
      headerName: '오프라인바코드',
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'quantity',
      headerName: '창고가용수량',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
    {
      field: 'pendingQuantity',
      headerName: '창고진행수량',
      minWidth: 20,
      headerAlign: 'center',
      align: 'center',
      editable: false,
    },
  ];
  return columns;
};

export default gridColumns;
