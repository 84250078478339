import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { CustomOrders } from '../consts/outbound/optionOrder';
import fetcher from '../libs/common/fetcher';

export interface RefCode {
  groupCode: string;
  code: string;
  value: string;
}

export interface RefCodeOption {
  [key: string]: RefCodeOptionValue[];
}

export interface RefCodeOptionValue {
  displayName: string;
  value: string;
}

function getValidResponse<T>(data: T[] | undefined): T[] {
  return data !== undefined && Array.isArray(data) ? data : [];
}

export const useRefCodes = () => {
  return useQuery<RefCodeOption>({
    queryKey: ['refCodes'],
    queryFn: async () => {
      const response = await fetcher('/commons/ref-codes');
      const validRefCodesData = getValidResponse<RefCode>(response);
      const refCodeOptions = validRefCodesData.reduce((acc: RefCodeOption, curr: RefCode) => {
        const { groupCode } = curr;
        const key: string = groupCode.charAt(0).toLowerCase() + groupCode.slice(1);
        const value: RefCodeOptionValue = {
          displayName: curr.value,
          value: curr.code,
        };
        acc[key] = (acc[key] || []).concat(value);
        return acc;
      }, {});
      Object.entries(refCodeOptions).forEach(([key, value]) => {
        if (key in CustomOrders && Array.isArray(CustomOrders[key])) {
          refCodeOptions[key] = value.sort(
            (a: RefCodeOptionValue, b: RefCodeOptionValue) =>
              CustomOrders[key].indexOf(a.value) - CustomOrders[key].indexOf(b.value)
          );
        } else {
          const sort = CustomOrders[key] ? CustomOrders[key] : 'asc';
          refCodeOptions[key] = _.orderBy(value, ['displayName'], [sort]);
        }
      });
      return refCodeOptions;
    },
    staleTime: Infinity,
  });
};
