import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Outlet, useLoaderData } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { drawerOpenAtom, shouldMountAppBarAtom, shouldMountDrawerAtom } from 'store';
import { useWarehouses } from '../../../queries/useWarehouse';
import authAtom, { Auth } from '../../../store/auth.recoil';
import { warehouseAtom, warehouseOptionsAtom } from '../../../store/outbound/warehouse.recoil';
import AppBar, { warehouseIdKey } from './AppBar';
import Drawer from './Drawer';
import { reloadOutletAtom } from '../../../store/common/menu.recoil';
import { useRefCodes } from '../../../queries/useRefCode';
import refCodeOptionsAtom from '../../../store/outbound/refCode.recoil';
import { useCarrierOptions, useCarriers } from '../../../queries/useCarrier';
import { carrierOptionsAtom, carriersAtom } from '../../../store/outbound/carrier.recoil';

const Layout = () => {
  const [drawerOpen, setDrawerOpen] = useRecoilState(drawerOpenAtom);
  const shouldMountAppBar = useRecoilValue(shouldMountAppBarAtom);
  const shouldMountDrawer = useRecoilValue(shouldMountDrawerAtom);
  const setAuth = useSetRecoilState(authAtom);
  const setUserWarehouse = useSetRecoilState(warehouseOptionsAtom);
  const { data: allWarehouseOptions = [] } = useWarehouses();
  const setWarehouse = useSetRecoilState(warehouseAtom);
  const reloadOutlet = useRecoilValue(reloadOutletAtom);
  const setRefCodeOptions = useSetRecoilState(refCodeOptionsAtom);
  const setCarriers = useSetRecoilState(carriersAtom);
  const setCarrierOptions = useSetRecoilState(carrierOptionsAtom);
  const { data: refCodes } = useRefCodes();
  const { data: carriers } = useCarriers();
  const { data: carrierOptions } = useCarrierOptions();
  const authData = useLoaderData() as Auth;

  useEffect(() => {
    // 사용하는 곳이 많으므로 여기서 한번에 처리
    if (refCodes) {
      setRefCodeOptions(refCodes);
    }
    if (carriers) {
      setCarriers(carriers);
    }
    if (carrierOptions) {
      setCarrierOptions(carrierOptions);
    }
    if (authData) {
      const {
        loginId,
        username,
        primaryWarehouseId,
        availableWarehouseIds,
        changePasswordRequired,
      } = authData;
      setAuth({
        loginId,
        username,
        primaryWarehouseId,
        availableWarehouseIds,
        changePasswordRequired,
      });
      setWarehouse(
        localStorage.getItem(warehouseIdKey)?.toString() ?? primaryWarehouseId.toString()
      );
      const warehouses = allWarehouseOptions.filter(v => availableWarehouseIds.includes(v.id));
      setUserWarehouse(warehouses);
    }
  }, [authData, allWarehouseOptions, refCodes, carriers, carrierOptions]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>케타포 GMS</title>
        </Helmet>
      </HelmetProvider>
      <Box sx={styles.container}>
        {shouldMountAppBar && <AppBar />}
        {shouldMountDrawer && (
          <Drawer
            open={drawerOpen}
            onOpen={() => setDrawerOpen(true)}
            onClose={() => setDrawerOpen(false)}
          />
        )}
        <Box key={reloadOutlet} component="main" sx={styles.outlet}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default Layout;

const styles = {
  container: {
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
    backgroundColor: '#f3f3f7',
  },
  outlet: {
    flex: 1,
    overflow: 'auto',
    backgroundColor: '#f3f3f7',
  },
};
