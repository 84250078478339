import React, { useState } from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Page from 'components/common/Layout/Page';
import Filter, { Form } from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import fetcher from 'libs/common/fetcher';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import { useRecoilValue } from 'recoil';
import { warehouseAtom, warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import { COLORS } from '../../../../consts/common/colors';
import gridColumns from './gridColDef';
import { Options } from '../../../../types/form';
import useConditionalSWR from '../../components/useConditionalSwr';
import refCodeOptionsAtom from '../../../../store/outbound/refCode.recoil';
import convertUser from '../../../../libs/common/convertUser';
import usePopup from '../../../../hooks/usePopup';
import { generateExcelDownload } from './services/excelDownload';
import LoadingButton from '@mui/lab/LoadingButton';

const defaultOption = { displayName: '전체', value: 'ALL' };

type InventoryHistory = {
  id: number;
  itemId: number;
  warehouseId: number;
  movedQuantity: number;
  itemCode: string;
  itemName: string;
  barcode: string;
  offlineBarcode: string;
  fromLocationCode: string;
  toLocationCode: string;
  lpnCode: string;
  createdUserName: string;
  createdLoginId: string;
  createdAt: string;
};

type InventoryHistoryResponse = {
  content: InventoryHistory[];
  totalElements: number;
  size: number;
  number: number;
};

type SearchQuery = {
  page?: number;
  size?: number;
  type?: string;
  warehouseId?: number;
};

const dateRangeTypeOptions: Options = [
  {
    displayName: '작업일',
    field: 'movedDate',
  },
];

const searchKeywordOptions: Options = [
  {
    displayName: '아이템코드',
    field: 'itemCode',
  },
  {
    displayName: '아이템명',
    field: 'itemName',
  },
  {
    displayName: '바코드',
    field: 'barcode',
  },
];

const searchLocationKeywordOptions: Options = [
  {
    displayName: '전체',
    field: 'bothLocationCode',
  },
  {
    displayName: '변경전',
    field: 'fromLocationCode',
  },
  {
    displayName: '변경후',
    field: 'toLocationCode',
  },
];

const gridTemplateColumns = [
  '30px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '60px',
  'minmax(55px, 2fr)',
  'minmax(55px, 1.4fr)',
  '36px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '36px',
  'minmax(55px, 1.4fr)',
  'minmax(55px, 1.4fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const defaultSize = 100;
const defaultPage = 0;
const StockMovementHistories = () => {
  const [hasSearched, setHasSearched] = useState(false);
  const [isExcelDownload, setIsExcelDownload] = useState(false);
  const { showErrorDialog, showSnackbar } = usePopup();
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: defaultPage,
    size: defaultSize,
    type: 'ALL',
    warehouseId: Number(globalWarehouse),
  });

  const { data, mutate, isValidating } = useConditionalSWR<InventoryHistoryResponse>(
    [`/inventories/movement-histories/search`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const refCodeOptions = useRecoilValue(refCodeOptionsAtom);
  const inventoryMovementHistoryTypeOptions = refCodeOptions?.inventoryMovementHistoryType || [];

  const makeRowIndex = (data: InventoryHistoryResponse) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: InventoryHistory, index: number) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
      id: index, // 단순 조회용이므로 id 커스텀함
      createdUser: convertUser(item.createdUserName, item.createdLoginId),
    }));
  };

  const handleExcelDownload = async () => {
    setIsExcelDownload(true);
    const response = await generateExcelDownload(queryParams);
    if (response?.status === 200) {
      showSnackbar({ message: '엑셀 다운로드가 완료되었습니다.', severity: 'success' });
    } else {
      showErrorDialog({
        title: '엑셀 다운로드 실패',
        errorMessage: response?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsExcelDownload(false);
  };

  const handleInitClick = async () => {
    setQueryParams({
      page: defaultPage,
      size: defaultSize,
      warehouseId: Number(globalWarehouse),
    });
    await mutate();
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    delete updatedForm[`dateType`];

    setQueryParams(({ size }) => ({ ...updatedForm, size, page: defaultPage }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };
  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        재고이동 이력 조회
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="창고"
          field="warehouseId"
          options={warehouseOption}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          defaultValue={globalWarehouse}
        />
        <Filter.Select
          label="조정사유"
          field="type"
          options={[defaultOption, ...inventoryMovementHistoryTypeOptions]}
          labelGridColumn="4/5"
          selectGridColumn="5/6"
          defaultValue={queryParams.type ?? 'ALL'}
        />
        <Filter.SearchWithSelect
          label="로케이션"
          searchOptions={searchLocationKeywordOptions}
          labelGridColumn="6/8"
          selectGridColumn="8/10"
          inputGridColumn="10/13"
        />
        <Filter.DateRangePickerWithSelect
          label="기간"
          rangeTypeOptions={dateRangeTypeOptions}
          gridRow={2}
          labelGridColumn="1/2"
          selectGridColumn="2/4"
          dateRangePickerGridColumn="4/6"
          rangeAmountSelectGridColumn="6/7"
        />
        <Filter.SearchWithSelect
          label="검색어"
          searchOptions={searchKeywordOptions}
          labelGridColumn="7/8"
          selectGridColumn="8/10"
          inputGridColumn="10/13"
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            재고이동 이력 목록
          </Typography>
          <LoadingButton
            variant="outlined"
            onClick={handleExcelDownload}
            loading={isExcelDownload}
            style={{ marginRight: '10px', textTransform: 'none' }}
          >
            엑셀다운로드
          </LoadingButton>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data?.content ? makeRowIndex(data) : []}
          columns={gridColumns()}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? defaultSize}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
    </Page>
  );
};

export default StockMovementHistories;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
  paperProps: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '520px',
    height: '460px',
  },
  backdropProps: {
    backgroundColor: COLORS.faintBlack,
  },
  dialogTitle: {
    paddingTop: '32px',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: '700',
    fontStyle: 'normal',
    lineHeight: '32px',
    letterSpacing: '-0.3px',
  },
  dialogSteps: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '76%',
  },
  otherStep: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.4px',
  },
  currentStep: {
    color: COLORS.ktown4u,
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.4px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-even',
    alignItems: 'center',
    width: '92%',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.4px',
  },
  scaledWeightWrapper: {
    width: '100%',
    height: '24%',
    border: `1px solid ${COLORS.darkLine}`,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    fontSize: 20,
    marginTop: 1,
  },
  scaledWeightTitle: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    fontSize: 20,
    pr: 2,
  },
  scaledWeightMessage: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    fontSize: 20,
    fontWeight: 'bold',
  },
};
