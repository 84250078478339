import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Page from 'components/common/Layout/Page';
import Filter, { Form } from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import { useStocktakingWithOutApplied } from '../../../../queries/useStocktaking';
import warehouseAtom from '../../../../store/outbound/warehouse.recoil';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import convertUser from '../../../../libs/common/convertUser';
import gridColDef from './gridColDef';
import LoadingButton from '@mui/lab/LoadingButton';
import usePopup from '../../../../hooks/usePopup';
import { generateExcelDownload } from './services/excelDownload';
import useConditionalSWR from '../../components/useConditionalSwr';
import fetcher from '../../../../libs/common/fetcher';

type stocktakingLocationItem = {
  id: number;
  warehouseId: number;
  locationCode: string;
  barcode: string;
  offlineBarcode: string;
  code: string;
  name: string;
  snapshotQty: number;
  verifiedQty: number;
  updatedUsername: string;
  updatedLoginId: string;
  updatedAt: string;
};

type StocktakingLocationItemResponse = {
  content: stocktakingLocationItem[];
  totalElements: number;
  size: number;
  number: number;
};

type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
  stocktakingIdA?: number;
  stocktakingIdB?: number;
};

const defaultOption = { displayName: '전체', value: 'ALL' };
const emptyOption = { displayName: '선택', value: '-' };

const gridTemplateColumns = [
  '36px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '50px',
  'minmax(55px, 2fr)',
  'minmax(55px, 1.3fr)',
  '50px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
  '50px',
  'minmax(55px, 0.6fr)',
  'minmax(55px, 0.6fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const StocktakingCompare = () => {
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const { data: stocktakingOption = [] } = useStocktakingWithOutApplied();
  const [hasSearched, setHasSearched] = useState(false);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
  });
  const [isExcelDownload, setIsExcelDownload] = useState(false);
  const { showErrorDialog, showSnackbar } = usePopup();

  const { data, isValidating, mutate } = useConditionalSWR<StocktakingLocationItemResponse>(
    [`/inventories/stocktakings/compare`, { ...queryParams, sort: 'id,desc' }],
    fetcher,
    hasSearched
  );

  const filteredStocktakingOptions = stocktakingOption.filter(
    option => option.warehouseId === Number(globalWarehouse)
  );

  const makeRowIndex = (data: StocktakingLocationItemResponse) => {
    const { content, totalElements, size, number } = data;

    return content.map((item: stocktakingLocationItem, index: number) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
      differenceQty: item.snapshotQty - item.verifiedQty,
      updatedUser: convertUser(item.updatedUsername, item.updatedLoginId),
    }));
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    setQueryParams(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    !hasSearched && setHasSearched(true);
    await mutate();
  };

  const handleInitClick = async () => {
    setQueryParams({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
    });
  };

  const handleExcelDownload = async () => {
    if (queryParams.stocktakingIdA === undefined || queryParams.stocktakingIdB === undefined) {
      showErrorDialog({
        title: '엑셀 다운로드 실패',
        errorMessage: '재고조사 A와 B를 선택해주세요.',
        buttons: [{ text: '확인' }],
      });
      return;
    }
    setIsExcelDownload(true);
    const response = await generateExcelDownload(queryParams);
    if (response?.status === 200) {
      showSnackbar({ message: '엑셀 다운로드가 완료되었습니다.', severity: 'success' });
    } else {
      showErrorDialog({
        title: '엑셀 다운로드 실패',
        errorMessage: response?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsExcelDownload(false);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        재고조사 비교 조회
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="재고조사 A"
          field="stocktakingIdA"
          options={[...filteredStocktakingOptions, emptyOption]}
          labelGridColumn="2/4"
          selectGridColumn="4/6"
          defaultValue={emptyOption.value}
        />
        <Filter.Select
          label="재고조사 B"
          field="stocktakingIdB"
          options={[...filteredStocktakingOptions, emptyOption]}
          labelGridColumn="6/8"
          selectGridColumn="8/11"
          defaultValue={emptyOption.value}
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            아이템별 수량 차이 목록
          </Typography>
          <LoadingButton
            variant="outlined"
            onClick={handleExcelDownload}
            loading={isExcelDownload}
            style={{ marginRight: '10px', textTransform: 'none' }}
          >
            엑셀다운로드
          </LoadingButton>
        </Box>
        <TightDataGridPro
          rows={hasSearched && data?.content ? makeRowIndex(data) : []}
          columns={gridColDef()}
          paginationMode="server"
          rowCount={hasSearched ? data?.totalElements ?? 0 : 0}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          onPageChange={page => setQueryParams(params => ({ ...params, page }))}
          pageSize={queryParams.size ?? DEFAULT_SIZE}
          onPageSizeChange={size => setQueryParams(params => ({ ...params, size }))}
          loading={isValidating}
          hasSearched={hasSearched}
        />
      </Stack>
    </Page>
  );
};

export default StocktakingCompare;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
};
