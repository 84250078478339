import { atom } from 'recoil';

export const packingIdsToPrintAtom = atom<number[]>({
  key: 'packingIdsToPrintAtom',
  default: [],
});

const packingsAtoms = {
  packingIdsToPrint: packingIdsToPrintAtom,
};

export default packingsAtoms;
