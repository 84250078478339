import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import borderedBoxStyle from '../../styles/borderedBox';
import { MAIN_WIDTH } from '../../consts/common/layout';

type Props = {
  gridTemplateColumns: string;
  children: ReactNode;
};

const SearchContainer = (props: Props) => {
  const { gridTemplateColumns, children } = props;

  return (
    <Box
      sx={{
        ...borderedBoxStyle,
        display: 'grid',
        gridTemplateColumns,
        rowGap: 1,
        columnGap: 2,
        minWidth: MAIN_WIDTH,
        p: 3,
        pb: 2.5,
      }}
    >
      {children}
    </Box>
  );
};

export default SearchContainer;
