import { useQuery } from '@tanstack/react-query';
import fetcher from '../libs/common/fetcher';

export interface Warehouse {
  id: number;
  fullCode?: string;
  name: string;
  value: string | number | boolean;
  isReflectInventory: boolean;
}

export interface WarehouseOption {
  id: number;
  fullCode?: string;
  displayName: string;
  value: string | number | boolean;
  isReflectInventory: boolean;
}

function getValidResponse<T>(data: T[] | undefined): T[] {
  return data !== undefined && Array.isArray(data) ? data : [];
}

export const useWarehouses = () => {
  return useQuery<WarehouseOption[]>({
    queryKey: ['warehouses'],
    queryFn: async () => {
      const response = await fetcher('/warehouses', { sort: 'id,desc' });
      const validSuppliers = getValidResponse<Warehouse>(response);
      return validSuppliers.map(v => ({
        displayName: v.name,
        id: v.id,
        value: v.id,
        fullCode: v.fullCode,
        isReflectInventory: v.isReflectInventory,
      }));
    },
    staleTime: Infinity,
  });
};
