import { useQuery } from '@tanstack/react-query';
import fetcher from '../libs/common/fetcher';

export interface Supplier {
  id: number;
  name: string;
}

export interface SupplierOption {
  id: number;
  value: string | number | boolean;
  displayName: string;
}

function getValidResponse<T>(data: T[] | undefined): T[] {
  return data !== undefined && Array.isArray(data) ? data : [];
}

export const useSupplier = () => {
  return useQuery<SupplierOption[]>({
    queryKey: ['suppliers'],
    queryFn: async () => {
      const response = await fetcher('/suppliers', { sort: 'id,desc' });
      const validSuppliers = getValidResponse<Supplier>(response);
      return validSuppliers.map(supplier => ({
        displayName: supplier.name,
        id: supplier.id,
        value: supplier.id,
      }));
    },
    staleTime: Infinity,
  });
};
