import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Page from 'components/common/Layout/Page';
import Filter, { Form } from 'components/common/Filter';
import TightDataGridPro from 'components/common/TightDataGridPro';
import { ROWS_PER_PAGE_OPTIONS } from 'consts/common/dataGrid';
import fetcher from 'libs/common/fetcher';
import pageTitle from 'styles/pageTitle';
import { listBoardTheme } from 'styles/customedMuiTheme';
import { useStocktakingWithOutApplied } from '../../../../queries/useStocktaking';
import warehouseAtom, { warehouseOptionsAtom } from '../../../../store/outbound/warehouse.recoil';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../../../consts/common/pageAndSize';
import gridColDef1 from './gridColDef1';
import gridColDef2 from './gridColDef2';
import InfoListSection from '../../../../components/common/typography/InfoListSection';
import convertWarehouseFullCode from '../../../../libs/warehouse/convertWarehouseFullCode';
import LoadingButton from '@mui/lab/LoadingButton';
import usePopup from '../../../../hooks/usePopup';
import {
  generateProgressByAreaExcelDownload,
  generateProgressByBinExcelDownload,
} from './services/excelDownload';
import useConditionalSWR from '../../components/useConditionalSwr';

type stocktakingProgressByBin = {
  id: number;
  warehouseId: number;
  areaCode: string;
  aisleCode: string;
  bayCode: string;
  stocktakingLocationCount: number;
  statusByOpenCount: number;
  statusByProcessingCount: number;
  statusByCompletedCount: number;
  statusByCompletedPercent: number;
};

type stocktakingProgressByArea = {
  id: number;
  warehouseId: number;
  areaCode: string;
  stocktakingLocationCount: number;
  statusByOpenCount: number;
  statusByProcessingCount: number;
  statusByCompletedCount: number;
  statusByCompletedPercent: number;
};

type StocktakingProgressByAreaResponse = {
  content: stocktakingProgressByArea[];
  totalElements: number;
  size: number;
  number: number;
};

type StocktakingProgressByBinResponse = {
  content: stocktakingProgressByBin[];
  totalElements: number;
  size: number;
  number: number;
};

type StocktakingResponse = {
  id: number;
  warehouseId: number;
  name: string;
  serialNumber: string;
};

type SearchQuery = {
  page?: number;
  size?: number;
  warehouseId?: number;
  stocktakingId?: number;
};

const defaultOption = { displayName: '전체', value: 'ALL' };
const emptyOption = { displayName: '선택', value: '-' };

const gridTemplateColumns = [
  '50px',
  'minmax(45px, 0.6fr)',
  'minmax(45px, 0.6fr)',
  '50px',
  'minmax(55px, 2fr)',
  'minmax(55px, 1.3fr)',
  '50px',
  'minmax(55px, 1fr)',
  'minmax(55px, 1fr)',
  '50px',
  'minmax(55px, 0.6fr)',
  'minmax(55px, 0.6fr)',
].reduce((prevValue, labelWidth) => prevValue + labelWidth + ' ', '');

const StocktakingProgress = () => {
  const globalWarehouse = useRecoilValue(warehouseAtom);
  const warehouseOption = useRecoilValue(warehouseOptionsAtom);
  const { data: stocktakingOption = [] } = useStocktakingWithOutApplied();
  const [hasSearched1, setHasSearched1] = useState(false);
  const [hasSearched2, setHasSearched2] = useState(false);
  const [isAreaExcelDownload, setIsAreaExcelDownload] = useState(false);
  const [isBinExcelDownload, setIsBinExcelDownload] = useState(false);
  const { showErrorDialog, showSnackbar } = usePopup();
  const [queryParams1, setQueryParams1] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
  });
  const [queryParams2, setQueryParams2] = useState<SearchQuery>({
    page: DEFAULT_PAGE,
    size: DEFAULT_SIZE,
    warehouseId: Number(globalWarehouse),
  });

  const {
    data: data1,
    isValidating: isValidating1,
    mutate: mutate1,
    error: error1,
  } = useConditionalSWR<StocktakingProgressByBinResponse>(
    [`/inventories/stocktakings/bins/progress/search`, { ...queryParams1, sort: 'id,desc' }],
    fetcher,
    hasSearched1
  );

  const {
    data: data2,
    isValidating: isValidating2,
    mutate: mutate2,
  } = useConditionalSWR<StocktakingProgressByAreaResponse>(
    [`/inventories/stocktakings/areas/progress/search`, { ...queryParams2, sort: 'id,desc' }],
    fetcher,
    hasSearched2
  );

  const { data: stocktakingData } = useConditionalSWR<StocktakingResponse>(
    [`/inventories/stocktakings/${queryParams1.stocktakingId}`],
    fetcher,
    hasSearched1
  );

  const filteredStocktakingOptions = stocktakingOption.filter(
    option => option.warehouseId === Number(globalWarehouse)
  );

  const warehouseName = convertWarehouseFullCode(
    stocktakingData?.warehouseId as number,
    warehouseOption
  );

  const infoList = [
    { name: '창고', value: warehouseName[0] },
    { name: '재고조사명', value: stocktakingData?.name },
    { name: '재고조사ID', value: stocktakingData?.serialNumber },
  ];

  const makeRowIndex1 = (data1: StocktakingProgressByBinResponse) => {
    const { content, totalElements, size, number } = data1;

    return content.map((item: stocktakingProgressByBin, index: number) => ({
      ...item,
      id: index, // 단순 조회용이므로 id 커스텀함
      rowIndex: totalElements - size * number - index,
      binCode: item.areaCode + '-' + item.aisleCode + '-' + item.bayCode,
    }));
  };

  const makeRowIndex2 = (data2: StocktakingProgressByAreaResponse) => {
    const { content, totalElements, size, number } = data2;

    return content.map((item: stocktakingProgressByArea, index: number) => ({
      ...item,
      rowIndex: totalElements - size * number - index,
    }));
  };

  const handleSearchClick = async (form: Form) => {
    const updatedForm = _.omitBy(form, o => o === defaultOption.value);
    if (updatedForm.stocktakingId === '-') {
      showSnackbar({ message: '재고조사 항목을 선택해주세요.', severity: 'warning' });
      return;
    }
    setQueryParams1(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    setQueryParams2(({ size }) => ({ ...updatedForm, size, page: DEFAULT_PAGE }));
    !hasSearched1 && setHasSearched1(true);
    !hasSearched2 && setHasSearched2(true);
    await mutate1();
    await mutate2();
  };

  const handleInitClick = async () => {
    setQueryParams1({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
    });
    setQueryParams2({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE,
      warehouseId: Number(globalWarehouse),
    });
  };

  const handleAreaExcelDownload = async () => {
    setIsAreaExcelDownload(true);
    const response = await generateProgressByAreaExcelDownload(queryParams2);
    if (response?.status === 200) {
      showSnackbar({ message: '엑셀 다운로드가 완료되었습니다.', severity: 'success' });
    } else {
      showErrorDialog({
        title: '엑셀 다운로드 실패',
        errorMessage: response?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsAreaExcelDownload(false);
  };

  const handleBinExcelDownload = async () => {
    setIsBinExcelDownload(true);
    const response = await generateProgressByBinExcelDownload(queryParams1);
    if (response?.status === 200) {
      showSnackbar({ message: '엑셀 다운로드가 완료되었습니다.', severity: 'success' });
    } else {
      showErrorDialog({
        title: '엑셀 다운로드 실패',
        errorMessage: response?.errorMessage,
        buttons: [{ text: '확인' }],
      });
    }
    setIsBinExcelDownload(false);
  };

  return (
    <Page>
      <Typography variant="h2" sx={pageTitle}>
        재고조사 진행현황 조회
      </Typography>
      <Filter gridTemplateColumns={gridTemplateColumns}>
        <Filter.Select
          label="재고조사"
          field="stocktakingId"
          options={[...filteredStocktakingOptions, emptyOption]}
          labelGridColumn="1/2"
          selectGridColumn="2/5"
          defaultValue={emptyOption.value}
        />
        <Divider sx={styles.divider} />
        <Filter.DefaultButtonGroup
          gridColumn="1/-1"
          gridRow={5}
          onInitClick={handleInitClick}
          onLookupClick={handleSearchClick}
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
      </Filter>
      <Stack spacing={1} sx={listBoardTheme.container}>
        <Box>
          <InfoListSection infoList={infoList} hideValue={!data1 || error1} />
        </Box>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            구역별 재고 조사 진행현황
          </Typography>
          <LoadingButton
            variant="outlined"
            onClick={handleAreaExcelDownload}
            loading={isAreaExcelDownload}
            style={{ marginRight: '10px', textTransform: 'none' }}
          >
            엑셀다운로드
          </LoadingButton>
        </Box>
        <Box sx={{ height: '280px' }}>
          <TightDataGridPro
            rows={hasSearched2 && data2?.content ? makeRowIndex2(data2) : []}
            columns={gridColDef2()}
            paginationMode="server"
            rowCount={hasSearched2 ? data2?.totalElements ?? 0 : 0}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onPageChange={page => setQueryParams2(params => ({ ...params, page }))}
            pageSize={queryParams2.size ?? DEFAULT_SIZE}
            onPageSizeChange={size => setQueryParams2(params => ({ ...params, size }))}
            loading={isValidating2}
            hasSearched={hasSearched2}
          />
        </Box>
        <Box sx={listBoardTheme.header}>
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            구획별 재고 조사 진행현황
          </Typography>
          <LoadingButton
            variant="outlined"
            onClick={handleBinExcelDownload}
            loading={isBinExcelDownload}
            style={{ marginRight: '10px', textTransform: 'none' }}
          >
            엑셀다운로드
          </LoadingButton>
        </Box>
        <Box sx={{ height: '280px' }}>
          <TightDataGridPro
            rows={hasSearched1 && data1?.content ? makeRowIndex1(data1) : []}
            columns={gridColDef1()}
            paginationMode="server"
            rowCount={hasSearched1 ? data1?.totalElements ?? 0 : 0}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onPageChange={page => setQueryParams1(params => ({ ...params, page }))}
            pageSize={queryParams1.size ?? DEFAULT_SIZE}
            onPageSizeChange={size => setQueryParams1(params => ({ ...params, size }))}
            loading={isValidating1}
            hasSearched={hasSearched1}
          />
        </Box>
      </Stack>
    </Page>
  );
};

export default StocktakingProgress;

const styles = {
  divider: {
    gridRow: 4,
    gridColumn: '1/-1',
    pb: 1,
    mb: 1,
  },
};
